import {
  IGetProjectETInformation,
  IGetProjectETInformationResponse,
  IGoToBackupResponse,
  ISaveProjectData,
  // ISaveUserSettings,
  ISaveToBom,
} from 'types/api/engineeringToolsApi';
import { ISaveUserSettings } from 'types/api/partnerHubApi';
import ApiCalls from './ApiCalls';

export default class PowerCoreAPI {
  static async getProjectInfo(requestData: IGetProjectETInformation): Promise<IGetProjectETInformationResponse> {
    const { data } = await ApiCalls.post<IGetProjectETInformationResponse, IGetProjectETInformation>(
      `Users/EngineeringTools/GetProjectInformation/`,
      requestData
    );
    return data;
  }

  static async goToBackup(requestData: IGetProjectETInformation): Promise<IGoToBackupResponse> {
    const { data } = await ApiCalls.post<IGoToBackupResponse, IGetProjectETInformation>(
      `Users/EngineeringTools/GoToBackup/`,
      requestData
    );
    return data;
  }

  static async downloadProject(url: string): Promise<Blob> {
    const { data } = await ApiCalls.get<Blob>('', url, { headers: { Authorization: 'NO_AUTH' } });
    return data;
  }

  static async saveProjectData(type: string, requestData: ISaveProjectData): Promise<string> {
    const { data } = await ApiCalls.put<string, ISaveProjectData>(`Users/EngineeringTools/SaveProjectData/${type}`, requestData);
    return data;
  }

  static async saveProjectReport(type: string, requestData: ISaveProjectData): Promise<string> {
    const { data } = await ApiCalls.put<string, ISaveProjectData>(
      `Users/EngineeringTools/saveProjectReport/${type}`,
      requestData
    );
    return data;
  }

  static async saveUserSettings(requestData: ISaveUserSettings): Promise<string> {
    const { data } = await ApiCalls.post<Promise<string>, ISaveUserSettings>(
      `Users/EngineeringTools/SaveUserSettings/`,
      requestData
    );
    return data;
  }

  static async saveToBom(requestData: ISaveToBom): Promise<string> {
    const { data } = await ApiCalls.put<string, ISaveToBom>(`Users/EngineeringTools/SaveToBom/`, requestData);
    return data;
  }
  //! Not used for now
  // static async saveUserSettings(requestData: ISaveUserSettings): Promise<string> {
  //   const { data } = await ApiCalls.post<Promise<string>, ISaveUserSettings>(`Users/SaveUserSettings/`, requestData);
  //   return data;
  // }
}
