import axios, { AxiosError, AxiosHeaders, AxiosRequestConfig, AxiosResponse } from 'axios';
import { gzipWorker } from 'workers';
import { EApiDataMethods, EApiMethods } from 'types/api/index.d';
import * as Sentry from '@sentry/react';
import { loginRequest } from 'authConfig';
import { msalInstance } from 'index';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

const URL = '/api/';

const { CancelToken } = axios;
const source = CancelToken.source();

const axiosConfig = {
  headers: { Pragma: 'no-cache', Authorization: '', 'Content-Type': 'application/json' },
  cancelToken: source.token,
  withCredentials: process.env.NODE_ENV !== 'test',
  baseURL: '',
  // timeout: false,
};

const processResponse = <T>(promise: Promise<AxiosResponse<T>>): Promise<AxiosResponse<T>> => {
  axiosConfig.headers = {
    ...axiosConfig.headers,
    // Authorization: `Bearer ${getToken() ?? ''}`,
    'Content-Type': 'application/json',
  };
  return promise
    .then((response) => {
      if (response.status >= 400) {
        // Request error handling.
        Sentry.captureException(response);
        return Promise.reject(new Error(`Invalid response: ${response.status}`));
      }
      return response;
    })
    .then((data) => data)
    .catch((err) => {
      const axiosError = err as AxiosError;
      if (axiosError.response?.status === 401) {
        const errorData = axiosError.response.data as { message: string; success: boolean };
        if (
          axiosError.response &&
          axiosError.response.data &&
          errorData.message &&
          !errorData.success &&
          errorData.message === 'Session failed'
        ) {
          msalInstance
            .logoutRedirect({
              account: msalInstance.getActiveAccount(),
              postLogoutRedirectUri: window.location.origin,
            })
            .catch((msalErr) => console.error(msalErr));
        }
        const account = msalInstance.getActiveAccount();
        if (account) {
          const msalRequest = {
            ...loginRequest,
            account,
          };
          // Clear local storage if old accessToken inside
          Object.keys(localStorage).forEach((localId) => {
            if (localStorage[localId]) {
              try {
                const parsedStorage = JSON.parse(localStorage.getItem(localId) as string) as Record<string, string>;
                if (parsedStorage && parsedStorage.target && parsedStorage.target.includes('openid')) {
                  localStorage.clear();
                }
              } catch (e) {
                // pass
              }
            }
          });
          msalInstance.acquireTokenRedirect(msalRequest).catch((error) => console.error(error));
        }
      }
      console.warn(err);
      if (axios.isAxiosError(err)) {
        Sentry.setExtra('Error data', {
          ErrorDescription: err.response?.data as string,
          Status: err.response?.status,
          Url: err.response?.config.url,
        });
        Sentry.captureException(err.response);
        console.warn(err.response);
      }
      throw err;
      // source.cancel('Operation canceled by the user.');
    });
};

export default class ApiCalls {
  static async get<T>(url: string, customUrl?: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return processResponse<T>(api.get<T>(customUrl ?? URL + url, config));
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  /* eslint-disable @typescript-eslint/explicit-module-boundary-types */
  static async delete<T, J>(url: string, data?: J): Promise<AxiosResponse<T>> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    return processResponse(api.delete(URL + url, { data }));
  }

  static async post<T, J>(url: string, data: J): Promise<AxiosResponse<T>> {
    return processResponse(api.post(URL + url, data));
  }

  static async put<T, J>(url: string, data: J): Promise<AxiosResponse<T>> {
    return processResponse(api.put(URL + url, data));
  }

  static async patch(url: string, data: any): Promise<AxiosResponse> {
    return processResponse(api.patch(URL + url, data));
  }

  static customRequest(
    url: string,
    method: EApiMethods | EApiDataMethods,
    config: AxiosRequestConfig,
    data: any
  ): Promise<AxiosResponse> {
    if (!config) {
      // eslint-disable-next-line no-param-reassign
      config = axiosConfig;
    }
    if (isDataMethod(method)) {
      return processResponse(api[method](url, data, config));
    }
    return processResponse(api[method](url, config));
  }
}

const isDataMethod = (method: EApiMethods | EApiDataMethods): method is EApiDataMethods => {
  return Object.keys(EApiDataMethods).indexOf(method) !== -1;
};

const api = axios.create({
  withCredentials: true,
  baseURL:
    window.location.hostname === 'localhost' ? 'https://localhost:5001' : process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(
  async (config) => {
    // The process before sending the request.
    const jsonData = JSON.stringify(config.data);
    // compress strings if over ~15KB
    if (typeof jsonData === 'string' && jsonData.length > 15120 && window.location.hostname !== 'localhost') {
      // eslint-disable-next-line no-param-reassign
      config = {
        ...config,
        data: await gzipWorker(jsonData),
        headers: new AxiosHeaders({
          'Content-Encoding': 'gzip',
          'Content-Type': 'application/json; charset=utf-8',
        }),
      };
    }
    const account = msalInstance.getActiveAccount();
    if (!account) {
      throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
    }
    const msalRequest = {
      ...loginRequest,
      account,
    };

    const response = await msalInstance.acquireTokenSilent(msalRequest).catch((error) => {
      // call acquireTokenRedirect in case of acquireTokenSilent failure
      // due to interaction required
      if (error instanceof InteractionRequiredAuthError) {
        msalInstance.acquireTokenRedirect(msalRequest).catch((err) => console.error(err));
      }
    });
    // const response = await msalInstance.ssoSilent({ loginHint: account.idTokenClaims?.preferred_username });
    // console.log(response);
    const bearer = `Bearer ${response ? response.accessToken : ''}`;
    // console.log(bearer);
    // config.headers.Authorization = bearer;
    if (config.headers) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = config.headers.Authorization === 'NO_AUTH' ? null : bearer;
    } else {
      // eslint-disable-next-line no-param-reassign
      config.headers = new AxiosHeaders({ Authorization: bearer });
    }

    return config;
  },
  (error) => {
    // Request error handling.
    Sentry.captureException(error);
    return Promise.reject(error);
  }
);
