import AppAPI from 'api/app';
import { loadUserProject, loadUserProjectPowerCore, projectActions } from 'project/actions';
import { userActions } from 'actions/user';
import { getObsoleteFamilies } from 'actions/variables';
import { batch } from 'react-redux';
import i18n from 'i18next';
import { ThunkAction } from 'types/known-actions';
import { ApplicationState } from 'types/store';
import { VariablesActions } from 'store/variables/actions';
import { phLanguages } from '../constants';

export const SET_APP_VERSION = 'SET_APP_VERSION';

export const initApp = (): ThunkAction<void> => (dispatch) => {
  return AppAPI.getAppVersion().then((data) => {
    const params = new window.URLSearchParams(window.location.search);
    const sldId = params.get('sldId') || undefined;
    const projectId = params.get('projectId') || undefined;
    const toolUrl = params.get('toolUrl') || undefined;
    const language = params.get('lang') || 'en';
    const country = params.get('country') || undefined;
    const user = params.get('user') || undefined;
    const projectIsUL = params.get('ul') === 'true';
    const name = params.get('name') || undefined;
    let initLang = 'en';

    if (language !== 'en' && phLanguages[language]) {
      initLang = phLanguages[language];
      i18n.changeLanguage(phLanguages[language]).catch((e) => console.log(e));
    }

    batch(() => {
      if (projectIsUL) {
        dispatch(VariablesActions.setVariables({ MainStandard: 'UL' }));
      }
      dispatch(projectActions.setAppVersion({ version: `Back:${data.version}|UI:${process.env.REACT_APP_VERSION}` }));
      dispatch(
        projectActions.setProjectInfo({
          info: {
            singleLineDiagramId: sldId,
            ul: projectIsUL,
            name,
          },
        })
      );
      if (toolUrl === 'PowerCore') {
        dispatch(
          projectActions.setPowerCoreData({
            powerCoreData: {
              singleLineDiagramId: projectId,
              toolUrl,
              user,
              name,
            },
          })
        );
      } else {
        dispatch(
          projectActions.setPartnerHubData({
            partnerHubData: {
              singleLineDiagramId: sldId,
              toolUrl,
              user,
            },
          })
        );
      }
      if (user) {
        dispatch(
          userActions.updateUserData({
            data: {
              language: initLang,
              userCountry: country,
              phLanguage: language,
              email: user,
            },
          })
        );
      }
      if (projectId && toolUrl === 'PowerCore') {
        dispatch(loadUserProjectPowerCore(projectId));
      } else if (sldId && toolUrl) {
        dispatch(loadUserProject(sldId));
      } else {
        dispatch(getObsoleteFamilies(true));
      }
    });
  });
};

export const getAppState = (): ThunkAction<ApplicationState> => (dispatch, getState) => {
  return getState();
};
