/* eslint-disable @typescript-eslint/naming-convention */
// TODO: Move ADD_PREVIOUS_STATE to undo after its implemented;
export enum DEVICES {
  UPDATE_ELEMENT_ERRORS = 'DEVICES/UPDATE_ELEMENT_ERRORS',
  ADD_ELEMENTS = 'DEVICES/ADD_ELEMENTS',
  UPDATE_ELEMENTS_POSITIONS = 'DEVICES/UPDATE_ELEMENTS_POSITIONS',
  UPDATE_LINKS = 'DEVICES/UPDATE_LINKS',
  REMOVE_CELLS = 'DEVICES/REMOVE_CELLS',
  UPDATE_ELEMENT_PARAMS = 'DEVICES/UPDATE_ELEMENT_PARAMS',
  DELETE_ELEMENT_PARAMS = 'DEVICES/DELETE_ELEMENT_PARAMS',
  ADD_RAW_ELEMENT = 'DEVICES/ADD_RAW_ELEMENT',
  UPDATE_ALL_ELEMENTS_PARAMS = 'DEVICES/UPDATE_ALL_ELEMENTS_PARAMS',
  ADD_PREVIOUS_STATE = 'DEVICES/ADD_PREVIOUS_STATE',
}

export enum PROJECT {
  SET_AUTO_COMPUTE = 'PROJECT/SET_AUTO_COMPUTE',
  SET_SELECTED_DEVICE = 'PROJECT/SET_SELECTED_DEVICE',
  SET_PROJECT_DATA = 'PROJECT/SET_PROJECT_DATA',
  SET_PROJECT_NAME = 'PROJECT/SET_PROJECT_NAME',
  SET_MACROCONVERSIONDATA = 'PROJECT/SET_MACROCONVERSIONDATA',
  SET_PROJECT_ERROR = 'PROJECT/SET_PROJECT_ERROR',
  SET_APP_VERSION = 'PROJECT/SET_APP_VERSION',
  SET_PROJECT_DIRTY = 'PROJECT/SET_PROJECT_DIRTY',
  SET_PROJECT_INFO = 'PROJECT/SET_PROJECT_INFO',
  SET_PROJECT_PROPAGATE_DIRTY = 'PROJECT/SET_PROJECT_PROPAGATE_DIRTY',
  SET_PROJECT_MESSAGES = 'PROJECT/SET_PROJECT_MESSAGES',
  LOAD_PROJECT = 'PROJECT/LOAD_PROJECT',
  SELECT_PAGE = 'PROJECT/SELECT_PAGE',
  OPEN_PAGE = 'PROJECT/OPEN_PAGE',
  CLOSE_PAGE = 'PROJECT/CLOSE_PAGE',
  UPDATE_PAGE_COUNTER = 'PROJECT/UPDATE_PAGE_COUNTER',
  ADD_PAGE = 'PROJECT/ADD_PAGE',
  UPDATE_PAGES_ORDER = 'PROJECT/UPDATE_PAGES_ORDER',
  SET_REPORT_FORCED = 'PROJECT/SET_REPORT_FORCED',
  SET_PARTNER_HUB_DATA = 'PROJECT/SET_PARTNER_HUB_DATA',
  SET_POWER_CORE_DATA = 'PROJECT/SET_POWER_CORE_DATA',
  UPDATE_PROJECT_PARAM = 'PROJECT/UPDATE_PROJECT_PARAM',
  TRIGGER_MULTIPLE_SELECTION = 'PROJECT/TRIGGER_MULTIPLE_SELECTION',
  REMOVE_PROJECT_MESSAGES = 'PROJECT/REMOVE_PROJECT_MESSAGES',
  SET_NEOGEARWIZARD = 'PROJECT/SET_NEOGEARWIZARD',
  UPDATE_DIAGRAM_FOR_PRINTING = 'PROJECT/UPDATE_DIAGRAM_FOR_PRINTING',
}

export enum VARIABLES {
  SET_VARIABLES = 'VARIABLES/SET_VARIABLES',
  SELECT_COUNTRY = 'VARIABLES/SELECT_COUNTRY',
}

export enum WIZARD {
  UPDATE_WIZARD_PARAMS = 'WIZARD/UPDATE_WIZARD_PARAMS',
  UPDATE_ELEMENT_ERRORS = 'WIZARD/UPDATE_ELEMENT_ERRORS',
  UPDATE_POWER_SUPPLY_ERRORS = 'WIZARD/UPDATE_POWER_SUPPLY_ERRORS',
}

export enum MODALS {
  SHOW_MODAL = 'MODALS/SHOW_MODAL',
  SHOW_MODAL_DIALOG = 'MODALS/SHOW_MODAL_DIALOG',
  CHANGE_TAB = 'MODALS/CHANGE_TAB',
  UPDATE_PARAMS = 'MODALS/UPDATE_PARAMS',
}

export enum COMPUTE {
  PUSH_NOTIFICATION = 'COMPUTE/PUSH_NOTIFICATION',
  SET_NOTIFICATIONS = 'COMPUTE/SET_NOTIFICATIONS',
  SET_COMPUTE_RUNNING = 'COMPUTE/SET_COMPUTE_RUNNING',
  SET_PROPAGATE_RUNNING = 'COMPUTE/SET_PROPAGATE_RUNNING',
  UPDATE_COMPUTE_PARAMS = 'COMPUTE/UPDATE_COMPUTE_PARAMS',
  SET_PROJECT_LOADING = 'COMPUTE/SET_PROJECT_LOADING',
  DEVICE_MESSAGES = 'COMPUTE/DEVICE_MESSAGES',
  UPDATE_LOAD_STATUS = 'COMPUTE/UPDATE_LOAD_STATUS',
  SET_COORDINATIONREQUEST_RUNNING = 'COMPUTE/SET_COORDINATIONREQUEST_RUNNING',
}

export enum CONNECTIONS {
  UPDATE_CONNECTIONS = 'CONNECTIONS/UPDATE_CONNECTIONS',
}

export enum CURVES {
  SET_CURVES_REQUEST_RUNNING = 'CURVES/SET_CURVES_REQUEST_RUNNING',
  SET_CURRENT_CURVES = 'CURVES/SET_CURRENT_CURVES',
  SET_CURRENT_CURVES_DIRTY = 'CURVES/SET_CURRENT_CURVES_DIRTY',
  SET_CUSTOM_CURVES = 'CURVES/SET_CUSTOM_CURVES',
  SET_CURVES_CONTROL = 'CURVES/SET_CURVES_CONTROL',
  SET_SELECTED_CURVE = 'CURVES/SET_SELECTED_CURVE',
  SET_CHECKED_CURVES = 'CURVES/SET_CHECKED_CURVES',
}

export enum LABELS {
  UPDATE_LABELS_ORDER = 'LABELS/UPDATE_LABELS_ORDER',
  UPDATE_LABELS_LIST = 'LABELS/UPDATE_LABELS_LIST',
  UPDATE_LABELS_STATUS = 'LABELS/UPDATE_LABELS_STATUS',
  SET_LABELS = 'LABELS/SET_LABELS',
}

export enum LOGO {
  SET_LOGO = 'LOGO/SET_LOGO',
}

export enum PAGES {
  ADD_PAGE = 'PAGES/ADD_PAGE',
  UPDATE_PAGE = 'PAGES/UPDATE_PAGE',
  DELETE_PAGE = 'PAGES/DELETE_PAGE',
}

export enum STANDARDS {
  SET_STANDARD = 'STANDARDS/SET_STANDARD',
}

export enum UNDO {
  ADD_PREVIOUS_STATE = 'UNDO/ADD_PREVIOUS_STATE',
  ADD_NEXT_STATE = 'UNDO/ADD_NEXT_STATE',
  REMOVE_UNDO = 'UNDO/REMOVE_UNDO',
  UNDO_DELETE_PAGE = 'UNDO/UNDO_DELETE_PAGE',
}

export enum USER {
  AAD_LOGIN_SUCCESS = 'USER/AAD_LOGIN_SUCCESS',
  SAVE_USER_DATA = 'USER/SAVE_USER_DATA',
  SAVE_ABB_COUNTRIES = 'USER/SAVE_ABB_COUNTRIES',
  SAVE_CURRENT_USER_VIEW = 'USER/SAVE_CURRENT_USER_VIEW',
  UPDATE_USER_DATA = 'USER/UPDATE_USER_DATA',
  SELECT_COUNTRY = 'USER/SELECT_COUNTRY',
  SAVE_USER_INTERNAL_DATA = 'USER/SAVE_USER_INTERNAL_DATA',
  UPDATE_SHOW_DEMO_STATUS = 'USER/UPDATE_SHOW_DEMO_STATUS',
  UPDATE_USER_MACROS = 'USER/UPDATE_USER_MACROS',
  PASTE_MACRO = 'USER/PASTE_MACRO',
}

export enum USER_SETTINGS {
  LOAD_PDF = 'USER_SETTINGS/LOAD_PDF',
  SET_USER_SETTING = 'USER_SETTINGS/SET_USER_SETTING',
  SET_XSD_VALIDATION_ERRORS = 'USER_SETTINGS/SET_XSD_VALIDATION_ERRORS',
}

export enum FILTERS {
  SET_FILTER = 'FILTERS/SET_FILTERS',
}

export enum DOCWEBDATA {
  LOAD_PROJECT = 'PROJECT/LOAD_PROJECT',
}
