/* eslint-disable no-param-reassign */
import ExportProjectAPI from 'api/projectExport';
import { updateLoadStatus } from 'compute/actions';
import { IGetProjectInformationResponse } from 'types/api/partnerHubApi';
import { IValidateXMLProject } from 'types/api/projectExport';
import { ThunkAction } from 'types/known-actions';
import { ApplicationState } from 'types/store';
import { elementDefaultState } from 'types/devices/constants';
import { Device } from 'types/devices/device';
import { PowerCore } from 'types/project/store';

export const setPHProjectData =
  (projectState: ApplicationState, partnerHubData: IGetProjectInformationResponse): ThunkAction<ApplicationState> =>
  (dispatch, getState) => {
    projectState.project.singleLineDiagramId = partnerHubData.id;
    projectState.project.partnerHub.singleLineDiagramId = partnerHubData.id;
    projectState.project.partnerHub.hookUrlUploadDocuments = partnerHubData.hookUrlUploadDocuments;
    projectState.project.partnerHub.hookUrlInsertDevices = partnerHubData.hookUrlInsertDevices;
    projectState.project.partnerHub.hookUrlUploadSettings = partnerHubData.hookUrlUploadSettings;
    projectState.project.partnerHub.projectId = partnerHubData.projectId;
    projectState.project.partnerHub.toolUrl = getState().project.partnerHub.toolUrl as string;
    projectState.project.partnerHub.singleLineDiagramName = partnerHubData.name as string;
    projectState.project.partnerHub.customerName = partnerHubData.customerName;
    projectState.project.partnerHub.userName = partnerHubData.userName;
    projectState.project.partnerHub.bomId = partnerHubData.bomId as string;
    projectState.project.partnerHub.user = partnerHubData.user;
    projectState.project.partnerHub.projectSettings = partnerHubData.projectSettings;
    projectState.project.partnerHub.projectName = partnerHubData.projectName;
    projectState.project.name = `${partnerHubData.projectName} - ${partnerHubData.name}`;
    projectState.project.PlantProject = partnerHubData.projectName;
    projectState.project.Customer = partnerHubData.customerName;
    projectState.project.PlantFile = `${partnerHubData.projectName} - ${partnerHubData.name}`;
    projectState.project.Filename = `${partnerHubData.projectName} - ${partnerHubData.name}.docweb`;
    projectState.project.id = partnerHubData.id;
    return projectState;
  };

export const setPowerCoreProjectData =
  (projectState: ApplicationState, powerCore: PowerCore): ThunkAction<ApplicationState> =>
  (dispatch, getState) => {
    projectState.project.singleLineDiagramId = powerCore.singleLineDiagramId;
    projectState.project.name = `${powerCore.projectName}`;
    projectState.project.PlantProject = powerCore.projectName ?? '';
    projectState.project.PlantFile = `${powerCore.projectName}`;
    projectState.project.Filename = `${powerCore.projectName}.docweb`;
    projectState.project.id = powerCore.singleLineDiagramId;
    projectState.project.powerCore.projectSettings = powerCore.projectSettings;
    return projectState;
  };

export const xmlValidation =
  (project: Partial<IValidateXMLProject>): ThunkAction<Promise<void>> =>
  (dispatch) => {
    return ExportProjectAPI.validateXMLproject(project).then((response) => {
      if (!response || response.status === 'EXCEPTION') {
        dispatch(updateLoadStatus(`Something wrong with XML validation API. Trying to draw the project.`, 50));
        if (response) {
          console.warn(response.message);
        }
      } else if ((response.status === 'Error' || response.status === 'Warrning') && response.message) {
        dispatch(updateLoadStatus(`Schema is not OK. Trying to draw the project. ${response.status}:${response.message}`, 50));
        dispatch({
          type: 'SET_XSD_VALIDATION_ERRORS',
          xsdValidationErrors: [{ type: response.status, message: response.message }],
        });
      } else {
        dispatch({
          type: 'SET_XSD_VALIDATION_ERRORS',
          xsdValidationErrors: [],
        });
        dispatch(updateLoadStatus('Schema is OK. Drawing project.', 50));
      }
    });
  };

export const clearNulls = (devices: Record<string, Device>): void => {
  Object.keys(devices).forEach((deviceId) => {
    Object.keys(devices[deviceId]).forEach((key) => {
      if (key === 'feederId' || key === 'targetPort') {
        return;
      }
      if (devices[deviceId][key] === null) {
        const { deviceType } = devices[deviceId];
        if (!deviceType) {
          console.warn(`${deviceId} has no deviceType`);
          return;
        }
        if (!elementDefaultState[deviceType]) {
          console.warn(`${deviceId} has device type not listed in elementDefaultState`);
          return;
        }
        if (elementDefaultState[deviceType][key]) {
          devices[deviceId][key] = elementDefaultState[deviceType][key];
          console.warn(`${deviceId}: ${key} has null value, set to default: ${elementDefaultState[deviceType][key] as string}`);
        } else {
          devices[deviceId][key] = undefined;
          console.warn(`${deviceId}: ${key} has null value, set to undefined`);
        }
      }
    });
  });
};
