import { IBomData, IBomProduct } from 'types/billOfMaterials';
import { Device, IProduct } from 'types/devices/device';
import { EDeviceObjectType, EDeviceType } from 'types/devices/enums.d';
import { isBomDevice } from 'types/devices/type-guards';
import { ThunkAction } from 'types/known-actions';

export const getBom = (): ThunkAction<Array<IBomData>> => (dispatch, getState) => {
  const { devices } = getState();
  const bomData: Array<IBomData> = [];
  const switchBoards = Object.keys(devices).filter(
    (swId) => devices[swId].deviceType === EDeviceType.LV_SWITCHBOARD || devices[swId].deviceType === EDeviceType.MV_SWITCHBOARD
  );
  switchBoards.forEach((swId) => {
    if (devices[swId].deviceType === EDeviceType.MV_SWITCHBOARD) {
      Object.keys(devices).forEach((deviceId) => {
        const device = devices[deviceId];
        if (device.objectType === EDeviceObjectType.MVTypicalUnit) {
          try {
            const switchboardId = devices[Object.keys(devices).find((id) => devices[id].MvSwitchBoardName === swId) ?? '']
              .MvSwitchBoardName as string;
            addTypicalsToBom(devices, switchboardId, device, bomData);
          } catch (error) {
            console.error(error);
          }
        }
      });
    }
    Object.keys(devices).forEach((deviceId) => {
      const device = devices[deviceId];
      if (device.SwitchBoardName === swId && isBomDevice(device)) {
        const folderName = devices[swId].SwitchBoardName as string;
        const deviceProduct = device.ProductIdList.product[0];
        const existingFolderIndex = bomData.findIndex((bom) => bom.Name === folderName);
        if (existingFolderIndex !== -1) {
          const duplicateIndex =
            bomData[existingFolderIndex]?.Product?.findIndex((prod) => prod.Code === deviceProduct.productId) ?? -1;
          if (duplicateIndex !== -1) {
            const duplicate: IBomProduct | undefined = bomData[existingFolderIndex]?.Product?.[duplicateIndex];
            const product = bomData[existingFolderIndex].Product;
            if (
              duplicate &&
              (!duplicate.Accessory ||
                duplicate.Accessory.length === 0 ||
                compareAccessories(duplicate.Accessory, deviceProduct.accessories.product)) &&
              product
            ) {
              product[duplicateIndex].Quantity += 1;
              const accessory = product[duplicateIndex].Accessory;
              if (accessory) {
                accessory.forEach((acc, aI) => {
                  accessory[aI].Quantity += 1;
                });
              }
              // bomData[existingFolderIndex].Quantity++;
            }
          } else {
            bomData[existingFolderIndex]?.Product?.push({
              Code: deviceProduct.productId,
              SelectionType: deviceProduct.productIdType,
              Description: device.ProductDescription ?? '',
              Accessory: getAccessories(deviceProduct.accessories),
              Quantity: 1,
            });
            // bomData[existingFolderIndex].Quantity++;
          }
        } else {
          bomData.push({
            Name: folderName,
            Type: 'SystemFolder',
            Quantity: 1,
            Product: [
              {
                Code: deviceProduct.productId,
                SelectionType: deviceProduct.productIdType,
                Description: device.ProductDescription ?? '',
                Accessory: getAccessories(deviceProduct.accessories),
                Sign: device.ObjectSign ?? '',
                Quantity: 1,
              },
            ],
          });
        }
      }
    });
  });

  bomData.forEach((bomFolder) => {
    if (
      bomFolder.Product &&
      bomFolder.Product.some((prod) => prod.Description.indexOf('MvSwitchboard') !== -1) &&
      bomFolder.Name.indexOf(' Total') === -1
    ) {
      // eslint-disable-next-line no-param-reassign
      bomFolder.Name = `${bomFolder.Name} [${bomFolder.Product.length}]`;
    }
  });
  return bomData;
};

const getAccessories = (accessories: IProduct['accessories']): Array<IBomProduct> => {
  let parsedAccessories;
  if (accessories && accessories.product && accessories.product.length > 0) {
    parsedAccessories = accessories.product.map((acc) => ({ Code: acc.productId, Description: acc.productIdType, Quantity: 1 }));
  }
  return parsedAccessories ?? [];
};

const compareAccessories = (acc1: Array<IBomProduct>, acc2: Array<IProduct>) => {
  if (acc1.length !== acc2.length) {
    return false;
  }
  return acc1.every((acc, i) => acc.Code === acc2[i].productId);
};

const addTypicalsToBom = (devices: Record<string, Device>, swId: string, typicalUnit: Device, bomData: Array<IBomData>) => {
  const folderName = devices[swId].SwitchBoardFamily as string;
  const existingFolderIndex = bomData.findIndex((bom) => bom.Name === folderName);
  const switchboardName = devices[swId].SwitchBoardName as string;
  if (
    existingFolderIndex !== -1 &&
    !bomData[existingFolderIndex]?.Product?.some((prod) => prod.Description === switchboardName)
  ) {
    bomData[existingFolderIndex]?.Product?.push({
      Description: switchboardName,
      Quantity: 1,
      Code: folderName,
    });
  } else if (existingFolderIndex === -1) {
    bomData.unshift({
      Name: folderName,
      Type: 'SystemFolder',
      Quantity: 1,
      Product: [
        {
          Description: switchboardName,
          Code: folderName,
          Quantity: 1,
        },
      ],
    });
  }
};
